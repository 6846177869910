function displayErrors(resp, self) {
    if (!resp.body)
        return false;
    let detail = resp.body.detail
    if (typeof(detail) != "string") {
        for (let item of detail) {
            let keys = item["loc"];
            let key = keys[keys.length - 1];
            if (key == "body") key = "__all__"
            self.$set(self.errors, key, [item["msg"]]);
        }
    } else {
        self.errors = {'__all__': [resp.body.detail || resp.statusText]};
    }
    return true;
}

module.exports = { displayErrors };

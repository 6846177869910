import { submitPostForm } from './pay.js';

function Robokassa(data) {
    const fields = {
        MerchantLogin: data.shop_id,
        OutSum: data.amount,
        InvId: data.order_id,
        Description: data.description,
        SignatureValue: data.sign,
    };

    if (env.DEBUG === 'True') {
        fields.IsTest = 1;
    }

    submitPostForm('https://auth.robokassa.ru/Merchant/Index.aspx', fields);
}

export { Robokassa };

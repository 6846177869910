var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pt-8 pt-md-5 pb-8 pb-md-14" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "form",
            {
              staticClass: "mb-6",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _vm._l(_vm.errors.__all__, function (error) {
                return _c("p", { staticClass: "mb-6 text-danger" }, [
                  _vm._v(_vm._s(error)),
                ])
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("div", { staticClass: "col" }, [
                    _c("h1", { staticClass: "mb-0 fw-bold" }, [
                      _vm._v("Order payout"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-6 text-muted" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$root.context.currency_symbol) +
                          _vm._s(_vm.$root.context.available_affiliate_amount) +
                          "\n                                is available to withdraw your income!\n                            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "amount" } },
                    [_vm._v("USDT (TRC20) amount:")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group mb-3" }, [
                    _c(
                      "span",
                      {
                        staticClass: "input-group-text",
                        attrs: { id: "basic-addon1" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$root.context.currency_symbol)
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.amount,
                          expression: "form.amount",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        min: "500",
                        value: "500",
                        max: parseInt(
                          _vm.$root.context.available_affiliate_amount
                        ),
                        id: "amount",
                        placeholder:
                          "Enter the amount. Minimum $500, maximum $2000 per order",
                        "aria-describedby": "basic-addon1",
                      },
                      domProps: { value: _vm.form.amount },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "amount", $event.target.value)
                        },
                      },
                    }),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { attrs: { clear: "both" } }),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.errors.amount, function (error) {
                    return _c(
                      "small",
                      { staticClass: "form-text text-danger" },
                      [_vm._v(_vm._s(error))]
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "tron_address" },
                    },
                    [_vm._v("Tron address:")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.tron_address,
                        expression: "form.tron_address",
                      },
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.errors.tron_address },
                    attrs: {
                      type: "text",
                      id: "tron_address",
                      placeholder: "Enter your tron address",
                    },
                    domProps: { value: _vm.form.tron_address },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "tron_address", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.errors.tron_address, function (error) {
                    return _c(
                      "small",
                      { staticClass: "form-text text-danger" },
                      [_vm._v(_vm._s(error))]
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                        Your request for withdrawal of funds is processed 3-5 working days!\n                    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn w-100 btn-primary",
                  attrs: { type: "submit", disabled: _vm.disabled },
                },
                [
                  _vm._v(
                    "\n                        Order\n                    "
                  ),
                ]
              ),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<section class="section-border border-primary">
    <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center gx-0 min-vh-100">
            <div class="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11">
                <img src="/assets/img/illustrations/illustration-4.png" alt="..." class="img-fluid">
            </div>
            <div class="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11" v-if="state">
                <h1 class="text-center form-title mb-3">You have successfully unsubscribed from mailing list "{{ $route.params.code }}"</h1>
                <div class="text-center">
                    <router-link class="btn btn-primary" :to="{path: '/dashboard'}">Console</router-link>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11" v-else>
                <h1 class="text-center form-title mb-3">Your link is not valid</h1>
                <div class="text-center">
                    <p>You may have already clicked on it?</p>
                    <a class="btn btn-primary" href="/">Home</a>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    data() {
        return {
            state: false,
            redirect: ""
        };
    },
    created() {
        this.$resource(this.$root.capiUrl + "users/unsubscribe{/uid}{/token}{/code}")
            .get(this.$route.params)
            .then(resp => {
                this.state = resp.body.state;
            })
            .catch(e => {
                this.state = false;
            });
    }
};
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import VueNotifications from 'vue-notifications'
import routes from './routes'
import services from './services'
import { EventBus } from "@/bus"
import miniToastr from 'mini-toastr'
import App from "./App"
import './filters'

// Notifications
const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn'
}
miniToastr.init({ types: toastTypes });
function toast({ title, message, type, timeout, cb }) {
    if (type == 'error')
        timeout = 100000000000;
    return miniToastr[type](message, title, timeout, cb)
}
const options = {
    success: toast,
    error: toast,
    info: toast,
    warn: toast
}
Vue.use(VueNotifications, options);
// End Notifications

Vue.use(VueRouter)
Vue.use(VueResource)

function getCookie(name) {
    let re = new RegExp(name + "=([^;]+)"),
        value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
}

function buildTitle (title) {
    document.title = `${document.title.split('|')[0]} | ${title}`
}

window.getFilter = function(namespace, keyName, defaultValue) {
    let data = JSON.parse(localStorage.getItem(namespace + '_filter') || '{}');
    if (keyName) {
        return data[keyName] || defaultValue
    }
    return data
}
window.setFilter = function(namespace, data) {
    let current = window.getFilter(namespace);
    for (let key in data)
        current[key] = data[key];
    localStorage.setItem(namespace + '_filter', JSON.stringify(current));
    return true;
}

var router = new VueRouter({
    mode: 'history',
    routes,
    linkActiveClass: 'active',
    scrollBehavior (to, from, savedPosition) {
        return {x: 0, y: 0}
    }
})
const unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
        document.execCommand('copy');
    } catch(err) {
        console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
};
const root = new Vue({
    el: "#app",
    router,
    render: h => h(App),
    computed: services,
    data: {
        context: {
            loaded: null,
        },
        siteProto: env.PROTOCOL,
        siteDomain: env.DOMAIN,
        siteName: env.SITE_NAME,
        siteEmail: env.SITE_EMAIL,
        siteTgSupport: env.TG_SUPPORT,
        apiUrl: "/x/",
        capiUrl: "/api/",
    },
    methods: {
        copyToClipboard(content) {
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(content);
            } else {
                unsecuredCopyToClipboard(content);
            }
            return true;
        },
        raise404() {
            this.$router.history.updateRoute(this.$router.resolve({
                name: '404',
            }).resolved);
        },
        raise500() {
            this.$router.history.updateRoute(this.$router.resolve({
                name: '500',
            }).resolved);
        },
        getContext() {
            this.Context.get().then(resp => this.updateContext(resp.body));
        },
        informContext() {
            EventBus.$emit('context:loaded', this.context);
        },
        updateContext(context) {
            const deflt = {
                loaded: true,
                promocode: localStorage.getItem("promocode"),
                referrer: localStorage.getItem("referrer")
            }
            this.context = Object.assign(deflt, context);
            this.informContext();
        },
        buildTitle
    },
    created() {
        this.buildTitle(this.$route.meta.title || this.$route.name)
        this.getContext();
        EventBus.$on('context:changed', this.getContext);
        EventBus.$on('context:updated', this.updateContext)
        EventBus.$on('context:request', this.informContext);
    },
    beforeDestroy() {
        EventBus.$off('context:changed', this.getContext);
        EventBus.$off('context:updated', this.updateContext)
        EventBus.$off('context:request', this.informContext);
    }
}).$mount('#app')

Vue.http.options.credentials = true;
Vue.http.options.headers = { 'X-CSRFToken': getCookie('csrftoken') }

router.beforeEach((to, from, next) => {
    buildTitle(to.meta.title || to.name)
    next()
});

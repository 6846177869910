function CloudPay (data) {
    var widget = new cp.CloudPayments();
    widget.pay(
        'charge',
        {
            publicId: data.publicId,
            description: data.description,
            amount: data.amount,
            currency: data.currency_code,
            accountId: data.owner_id,
            invoiceId: data.order_id,
        },
        {
            onSuccess: function (options) {
                window.location.href = "/billing"
            },
            onFail: function (reason, options) {
                window.location.href = "/billing"
            }
        }
    );
}

export { CloudPay }

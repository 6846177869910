function Payeer (data) {
    var form = document.createElement('FORM')
    form.name = 'payeerForm'
    form.method = 'POST'
    form.action = 'https://payeer.com/merchant/'
    var fields = {
        m_shop: data.shop_id,
        m_orderid: data.order_id,
        m_amount: data.amount,
        m_curr: data.currency_code,
        m_desc: data.description,
        m_sign: data.sign,
        m_process: "send",
        lang: "en"
    }
    for (let name in fields) {
        let input = document.createElement('INPUT')
        input.type = 'HIDDEN'
        input.name = name
        input.value = fields[name]
        form.appendChild(input)
    }
    document.body.appendChild(form);
    form.submit();
}

export { Payeer }

export function submitPostForm(action, fields) {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = action;

    Object.entries(fields).forEach(([name, value]) => {
        addHiddenField(form, name, value);
    });

    document.body.appendChild(form);
    form.submit();
    form.remove();
}

function addHiddenField(form, name, value) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    form.appendChild(input);
}

<template>
<section class="section-border border-primary">
    <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center gx-0 min-vh-100">
            <div class="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11">
                <img src="/assets/img/illustrations/illustration-4.png" alt="..." class="img-fluid">
            </div>
            <div class="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11" v-if="changed">
                <div class="alert alert-success" role="alert">
                    <h4 class="alert-heading">Excellent!</h4>
                    <p>
                        You have successfully changed your password.<br>
                        You can now <router-link :to="{path: '/dashboard'}">login</router-link>
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11" v-else>
                <h1 class="mb-0 fw-bold text-center">Password reset</h1>
                <p class="mb-6 text-center text-muted" v-if="Object.keys(errors).length < 1">Enter your new password</p>
                <p class="mb-6 text-center text-danger" v-for="error in errors.__all__">{{ error }}</p>
                <form class="mb-6" @submit.prevent="reset">
                    <div class="form-group mb-5">
                        <label class="form-label" for="password">Password</label>
                        <input type="password" class="form-control" placeholder="Enter your password" v-model="form.password1"
                               id="password" :class="{'is-invalid': errors.password1}">
                        <small class="form-text text-danger" v-for="error in errors.password1">{{ error }}</small>
                    </div>
                    <div class="form-group mb-5">
                        <label class="form-label" for="password2">Repeat password</label>
                        <input type="password" class="form-control" placeholder="Enter your password again" v-model="form.password2"
                               id="password2" :class="{'is-invalid': errors.password2}">
                        <small class="form-text text-danger" v-for="error in errors.password2">{{ error }}</small>
                    </div>
                    <button class="btn w-100 btn-primary" type="submit" :disabled="disabled">Save new password</button>
                </form>
                <p class="mb-0 fs-sm text-center text-muted">
                    Forgot your password? <router-link :to="{path: '/login'}">Login</router-link>.
                </p>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { EventBus } from "@/bus";
import { displayErrors } from "utils/string"
export default {
    data() {
        return {
            form: {},
            errors: {},
            disabled: false,
            changed: false
        }
    },
    methods: {
        reset() {
            this.disabled = true
            this.errors = {}
            let data = Object.assign(
                this.form,
                this.$route.params
            )
            this.$root.Reset.save(data).then(resp => {
                this.errors = resp.body.errors || {}
                if (resp.body.state) {
                    this.changed = true
                } else {
                    displayErrors(resp, this);
                }
                this.disabled = false
            }).catch(resp => {
                displayErrors(resp, this);
            }).finally(() => {
                this.disabled = false
            })
            setTimeout(() => {
                this.disabled = false
            }, 5000)
        }
    }
}
</script>

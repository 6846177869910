<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="mb-6 text-danger" v-for="error in errors.__all__">{{ error }}</p>
                <form class="mb-6" @submit.prevent="change">
                    <div class="form-group mb-5">
                        <label class="form-label" for="old_password">Old password</label>
                        <input type="password" class="form-control" :class="{'is-invalid': errors.old_password}" id="old_password"
                               placeholder="Enter your password" v-model="form.old_password">
                        <small class="form-text text-danger" v-for="error in errors.old_password">{{ error }}</small>
                    </div>
                    <div class="form-group mb-5">
                        <label class="form-label" for="password">New password</label>
                        <input type="password" class="form-control" :class="{'is-invalid': errors.password1}" id="password"
                               placeholder="Enter new password" v-model="form.password1">
                        <small class="form-text text-danger" v-for="error in errors.password1">{{ error }}</small>
                    </div>
                    <div class="form-group mb-5">
                        <label class="form-label" for="password2">Repeat password</label>
                        <input type="password" class="form-control" :class="{'is-invalid': errors.password2}" id="password2"
                               placeholder="Enter new password again" v-model="form.password2">
                        <small class="form-text text-danger" v-for="error in errors.password2">{{ error }}</small>
                    </div>
                    <button class="btn w-100 btn-primary" type="submit" :disabled="disabled">
                        Change password
                    </button>
                </form>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { NotifyMixin } from '@/mixins'
import { displayErrors } from "utils/string"
export default {
    mixins: [NotifyMixin],
    data() {
        return {
            errors: {},
            form: {},
            disabled: false
        }
    },
    methods: {
        change() {
            this.errors = {}
            this.disabled = true
            this.$root.Password.update(this.form).then(resp => {
                this.errors = resp.body.errors || {}
                if (resp.body.state) {
                    this.successNotify({ message: "Successful" });
                } else {
                    displayErrors(resp, this);
                }
            }).catch(resp => {
                displayErrors(resp, this);
            }).finally(() => {
                this.disabled = false
            })
        }
    }
}
</script>
